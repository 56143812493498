import React, { useState } from 'react';
import {
    Grid, Typography, Paper, Button, Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CodeBlock from '../../../../General/CodeBlock';
import ProfileQuestionRow from './ProfileQuestionRow';

const styles = {
    title: {
        fontSize: '30px',
    },
    header: {
        fontSize: '25px',
    },
    questionLabel: {
        fontSize: '24px',
    },
    body: {
        fontSize: '13px',
    },
    content: {
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '64px',
        marginBottom: '250px',
    },
    bodyText: {
        fontSize: '13px',
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontSize: 13,
    },
    buttonContainer: {
        padding: '20px',
    },
    buttonPadding: {
        marginRight: '20px',
    },
    background: {
        backgroundColor: '#E8E8E8',
    },
    input: {
        backgroundColor: '#FFFFFF',
    },
    questionContainer: {
        marginTop: '50px',
    },
    secondaryQuestionText: {
        color: '#808080',
    },
};

const ProfileTab = ({
    value,
    index,
    editingProfileInformation,
    classes,
    dispatch,
    nextTab,
    courseId,
    applicationOverviewText,
}) => {
    const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

    const handleChange = id => (event) => {
        const indexToChange = editingProfileInformation.findIndex(object => object.id === id);
        const updatedApplicant = editingProfileInformation.map((item, arrayIndex) => {
            if (arrayIndex !== indexToChange) {
                return item;
            }
            return {
                ...item,
                answer: event.target.value,
            };
        });
        dispatch({ type: 'SET_EDITING_PROFILE_APPLICANT', payload: updatedApplicant });
        dispatch({ type: 'SET_UNSAVED_PROFILE_CHANGE', payload: true });
    };

    // const saveProfileInformation = () => {
    //     dispatch({
    //         type: 'PUT_APPLICANT_PROFILE_INFORMATION',
    //         payload: editingProfileInformation,
    //     });
    //     dispatch({ type: 'UNSET_UNSAVED_PROFILE_CHANGE', payload: true });
    // };

    const validateForm = () => {
        setSubmitButtonPressed(true);
        for (let i = 0; i < editingProfileInformation.length; i += 1) {
            if (!editingProfileInformation[i].answer) {
                dispatch({
                    type: 'SET_GLOBAL_MODAL',
                    payload: {
                        modalOpen: true,
                        title: 'Missing Fields',
                        body: 'Please complete all required fields before continuing.',
                    },
                });
                return false;
            }
        }
        return true;
    };

    const submitProfileInformation = (event) => {
        event.preventDefault();
        const valid = validateForm();
        if (!valid) {
            return;
        }
        dispatch({
            type: 'PUT_APPLICANT_PROFILE_INFORMATION',
            payload: {
                profileQuestions: editingProfileInformation,
                courseId,
                nextTab,
            },
        });
        dispatch({ type: 'UNSET_UNSAVED_PROFILE_CHANGE', payload: true });
    };

    return (
        <>
            {value === index && (
                <Paper className={classes.content}>
                    <Typography variant="h2">
                        {applicationOverviewText.profile_question_label}
                    </Typography>
                    <br />
                    <ReactMarkdown
                        align="left"
                        remarkPlugins={[remarkGfm]}
                        components={{ code: CodeBlock }}
                        rehypePlugins={[rehypeRaw]}
                        linkTarget="_blank"
                    >
                        {applicationOverviewText.profile_question_description}
                    </ReactMarkdown>
                    <br />
                    <Divider />
                    <form onSubmit={submitProfileInformation}>
                        {
                            editingProfileInformation
                            && editingProfileInformation.map((profileInfo, arrayIndex) => (
                                <ProfileQuestionRow
                                    key={profileInfo.id}
                                    profileInfo={profileInfo}
                                    classes={classes}
                                    handleChange={handleChange}
                                    submitButtonPressed={submitButtonPressed}
                                    questionIndex={arrayIndex}
                                />
                            ))
                        }
                        <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                            {/* <Button
                                className={classes.buttonPadding}
                                variant="contained"
                                onClick={saveProfileInformation}
                            >
                                Save Your Progress
                            </Button> */}
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                Save Your Application and Continue
                            </Button>
                        </Grid>
                    </form>
                </Paper>
            )}
        </>
    );
};

ProfileTab.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    editingProfileInformation: PropTypes.instanceOf(Array).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    nextTab: PropTypes.func.isRequired,
    courseId: PropTypes.number.isRequired,
    applicationOverviewText: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    courseId: state.applicant.editingCourseId,
    editingProfileInformation: state.applicant.editingProfileInformation,
});

export default connect(mapStateToProps)(withStyles(styles)(ProfileTab));
