import React, { useState, useEffect, useCallback } from 'react';
import ReactGA from 'react-ga';
import {
    AppBar,
    Tabs,
    Tab,
    Paper,
    Typography,
    Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Prompt, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import StartTab from './Tabs/StartTab';
import GeneralTab from './Tabs/GeneralTab/GeneralTab';
import PersonalTab from './Tabs/ProfileTab/ProfileTab';
import TechnicalTab from './Tabs/TechnicalTab';
import ReviewTab from './Tabs/ReviewTab/ReviewTab';
import ReactPixel from '../../../helpers/FacebookPixel';

if (process.env.REACT_APP_FACEBOOK_PIXEL) {
    const initialized = (typeof window !== 'undefined') && !!window.fbq;
    if (!initialized) {
        ReactPixel.init(
            process.env.REACT_APP_FACEBOOK_PIXEL,
            {},
            { debug: false, autoConfig: true },
        );
    }
    ReactPixel.pageView();
}

if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

const styles = {
    tabs: {
        backgroundColor: 'white',
        color: 'black',
        marginTop: '10px',
    },
    title: {
        fontSize: '30px',
    },
    header: {
        fontSize: '25px',
    },
    content: {
        padding: '30px',
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontSize: 13,
    },
};

const ApplicationTabsNoLogic = ({
    technicalTitle,
    startTabText,
    classes,
    unsavedChanges,
    courseId,
    match,
    history,
    applicationStep,
    programType,
}) => {
    let values = [];
    let message = '';
    let currentStep = '';
    const [value, setValue] = useState(0);
    const location = useLocation();
    const stableHistory = useCallback(history, []);

    useEffect(() => {
        const currentPath = location.pathname + location.search;
        // console.log('PAGEVIEW', currentPath);
        if (process.env.REACT_APP_GA_TRACKING_ID) {
            ReactGA.pageview(currentPath);
        }
        if (process.env.REACT_APP_HUBSPOT_TRACKING
            && (typeof window !== 'undefined')
            && !window.HubSpotConversations) {
            const hubspotScript = document.createElement('script');
            hubspotScript.src = `https://js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_TRACKING}.js`;
            hubspotScript.async = true;
            document.body.appendChild(hubspotScript);
        }
    }, [location]);

    const setStep = (step) => {
        if (currentStep === step) {
            return;
        }
        switch (step) {
        case 'start':
            setValue(0);
            break;
        case 'general':
            setValue(1);
            break;
        case 'personal':
            setValue(2);
            break;
        case 'technical':
            setValue(3);
            break;
        case 'review':
            setValue(4);
            break;
        default:
            setValue(0);
            break;
        }
        currentStep = step;
    };

    const setTab = (index) => {
        if (value !== index) {
            switch (index) {
            case 0:
                history.push(`/courses/${programType}/apply/start`);
                break;
            case 1:
                history.push(`/courses/${programType}/apply/general`);
                break;
            case 2:
                history.push(`/courses/${programType}/apply/personal`);
                break;
            case 3:
                history.push(`/courses/${programType}/apply/technical`);
                break;
            case 4:
                history.push(`/courses/${programType}/apply/review`);
                break;
            case 5:
                history.push(`/courses/${programType}/apply/thanks`);
                break;
            default:
                history.push(`/courses/${programType}/apply/start`);
                break;
            }
            if (index >= 0 && index <= 5) {
                setValue(index);
            }
        }
    };

    const stableSetStep = useCallback(setStep, []);
    const stableSetTab = useCallback(setTab, []);

    useEffect(() => {
        if (match && match.params && match.params.step && match.params.step !== 'thanks' && applicationStep === 'thanks') {
            stableHistory.push(`/courses/${programType}/apply/thanks`);
        } else if (match && match.params && !match.params.step) {
            // If we don't have a step in the URL, default to the application step
            stableSetTab(applicationStep);
        } else if (match.params.step === 'thanks' && applicationStep !== 'thanks') {
            // We are on the thanks page but shouldn't be
            stableSetTab(applicationStep);
        }
    }, [applicationStep, match, stableSetTab, stableHistory, programType]);

    useEffect(() => {
        if (match && match.params && match.params.step) {
            stableSetStep(match.params.step);
        }
    }, [match, stableSetStep]);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const a11yProps = index => ({
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    });

    const nextTab = () => {
        setTab(value + 1);
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, keyValue] of Object.entries(unsavedChanges)) {
        if (keyValue === true) {
            if (key === 'logic' && courseId === 2) {
                values.push('reasoning');
            } else if (key === 'technical' && courseId === 2) {
                values.push('creative');
            } else if (key === 'logic' && courseId === 3) {
                // values.push('reasoning');
            } else if (key === 'technical' && courseId === 3) {
                values.push('creative');
            } else {
                values.push(key);
            }
        }
    }

    values = Array.from(values).join(' & ');
    message = `You have unsaved edits in the ${values} tab. Are you sure you want to leave? `;
    return (
        <>
            {
                match && match.params && match.params.step === 'thanks'
                && (
                    <Paper>
                        <Typography className={classes.title}>{'Congratulations, You\'re Done!'}</Typography>
                        <Typography>
                            You’ve taken a big first step in your journey towards a
                            new career. Here’s what you can expect next:
                        </Typography>
                        <ol>
                            <li>We will review your application.</li>
                            <li>
                                Within 2 weeks, you will receive an email
                                that either invites you to an online interview
                                with our staff, or gives you feedback on what
                                in your application wasn’t as strong as other candidates.
                            </li>
                        </ol>
                        <Typography className={classes.header}>
                            Questions?
                        </Typography>
                        <Typography>
                            If you have any questions right now, reach out via email at
                            <a href="mailto:admissions@primeacademy.io?subject=Admissions%20Question"> admissions@primeacademy.io</a>
                        </Typography>
                    </Paper>
                )
            }
            {
                match && match.params && match.params.step !== 'thanks'
                && (
                    <>

                        <Prompt
                            // Use a standard alert dialog if the user
                            // leaves the page when changes are detected.
                            when={unsavedChanges.general
                                || unsavedChanges.technical
                                || unsavedChanges.logic
                                || unsavedChanges.profile}
                            message={toLocation => (toLocation.pathname.startsWith(`/courses/${programType}/apply/`)
                                ? true
                                : message
                            )}
                        />
                        <AppBar className={classes.tabs} position="static">
                            <Tabs indicatorColor="primary" variant="fullWidth" value={value} onChange={handleChange}>
                                <Tab label="Start" {...a11yProps(0)} />
                                <Tab
                                    label={
                                        unsavedChanges.general ? (
                                            <Tooltip title="Changes will be lost unless you save your application">
                                                <span>General*</span>
                                            </Tooltip>
                                        ) : (
                                            <span>General</span>
                                        )
                                    }
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    label={
                                        unsavedChanges.profile ? (
                                            <Tooltip title="Changes will be lost unless you save your application">
                                                <span>Personal*</span>
                                            </Tooltip>
                                        ) : (
                                            <span>Personal</span>
                                        )
                                    }
                                    {...a11yProps(2)}
                                />
                                <Tab
                                    label={
                                        unsavedChanges.technical ? (
                                            <Tooltip title="Changes will be lost unless you save your application">
                                                <span>{`${technicalTitle}*`}</span>
                                            </Tooltip>
                                        ) : (
                                            <span>{technicalTitle}</span>
                                        )
                                    }
                                    {...a11yProps(3)}
                                />
                                <Tab label="Review" {...a11yProps(4)} />
                            </Tabs>
                        </AppBar>
                        <StartTab
                            value={value}
                            index={0}
                            applicationOverviewText={startTabText}
                            nextTab={nextTab}
                        />
                        <GeneralTab
                            value={value}
                            index={1}
                            nextTab={nextTab}
                        />
                        <PersonalTab
                            value={value}
                            index={2}
                            nextTab={nextTab}
                            applicationOverviewText={startTabText}
                        />
                        <TechnicalTab
                            value={value}
                            index={3}
                            nextTab={nextTab}
                            applicationOverviewText={startTabText}
                        />
                        <ReviewTab
                            value={value}
                            index={4}
                            nextTab={nextTab}
                            programType={programType}
                            unsavedChanges={unsavedChanges}
                        />
                    </>
                )
            }

        </>
    );
};

ApplicationTabsNoLogic.propTypes = {
    applicationStep: PropTypes.string.isRequired,
    logicTitle: PropTypes.string.isRequired,
    technicalTitle: PropTypes.string.isRequired,
    startTabText: PropTypes.instanceOf(Object).isRequired,
    courseId: PropTypes.number.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    unsavedChanges: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.shape({
        params: PropTypes.instanceOf(Object).isRequired,
    }).isRequired,
    programType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    unsavedChanges: state.applicant.unsavedChanges,
    courseId: state.applicant.editingCourseId,
    applicationStep: state.applicant.applicationStep,
});

export default connect(mapStateToProps)(withStyles(styles)(ApplicationTabsNoLogic));
