import React, { useState } from 'react';
import {
    Grid, Typography, Paper, Button, Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LogicQuestionRow from './LogicQuestionRow';

const styles = {
    title: {
        fontSize: '30px',
    },
    header: {
        fontSize: '25px',
    },
    body: {
        fontSize: '13px',
    },
    content: {
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '64px',
        marginBottom: '250px',
    },
    bodyText: {
        fontSize: '13px',
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontSize: 13,
    },
    buttonContainer: {
        padding: '20px',
    },
    buttonPadding: {
        marginRight: '20px',
    },
    responsiveImage: {
        maxWidth: '100%',
        height: 'auto',
    },
};

const LogicTab = ({
    value, index, editingLogicInformation, user,
    dispatch, classes, courseId, nextTab, applicationOverviewText,
}) => {
    const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

    const handleChange = id => (event) => {
        const indexToChange = editingLogicInformation.findIndex(object => object.id === id);
        const updatedApplicant = editingLogicInformation.map((item, arrayIndex) => {
            if (arrayIndex !== indexToChange) {
                return item;
            }
            return {
                ...item,
                answer: event.target.value,
            };
        });
        dispatch({ type: 'SET_EDITING_LOGIC_APPLICANT', payload: updatedApplicant });
        dispatch({ type: 'SET_UNSAVED_LOGIC_CHANGE', payload: true });
    };

    // const saveLogicInformation = () => {
    //     dispatch({
    //         type: 'PUT_APPLICANT_LOGIC_INFORMATION',
    //         payload: editingLogicInformation,
    //     });
    //     dispatch({ type: 'UNSET_UNSAVED_LOGIC_CHANGE', payload: false });
    // };

    const validateForm = () => {
        setSubmitButtonPressed(true);
        for (let i = 0; i < editingLogicInformation.length; i += 1) {
            if (!editingLogicInformation[i].answer) {
                dispatch({
                    type: 'SET_GLOBAL_MODAL',
                    payload: {
                        modalOpen: true,
                        title: 'Missing Fields',
                        body: 'Please complete all required fields before continuing.',
                    },
                });
                return false;
            }
        }
        return true;
    };

    const submitLogicInformation = (event) => {
        event.preventDefault();
        const valid = validateForm();
        if (!valid) {
            return;
        }
        dispatch({
            type: 'PUT_APPLICANT_LOGIC_INFORMATION',
            payload: {
                logicQuestions: editingLogicInformation,
                courseId,
                nextTab,
            },
        });
    };
    return (
        <>
            {value === index && (
                <Paper className={classes.content}>
                    <Typography variant="h2">
                        {applicationOverviewText.logic_question_label}
                    </Typography>
                    <Typography>
                        {applicationOverviewText.logic_question_description}
                    </Typography>
                    <br />
                    <Divider />
                    <form onSubmit={submitLogicInformation}>
                        {
                            editingLogicInformation
                            && editingLogicInformation.map(logicInfo => (
                                <LogicQuestionRow
                                    key={logicInfo.id}
                                    logicInfo={logicInfo}
                                    user={user}
                                    classes={classes}
                                    handleChange={handleChange}
                                    submitButtonPressed={submitButtonPressed}
                                />
                            ))
                        }
                        <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                            {/* <Button
                                variant="contained"
                                className={classes.buttonPadding}
                                onClick={saveLogicInformation}
                            >
                                Save Your Progress
                            </Button> */}
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                Save Your Application and Continue
                            </Button>
                        </Grid>
                    </form>
                </Paper>
            )}
        </>
    );
};

LogicTab.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    editingLogicInformation: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    courseId: PropTypes.number.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    nextTab: PropTypes.func.isRequired,
    applicationOverviewText: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    editingLogicInformation: state.applicant.editingLogicInformation,
    user: state.user,
    courseId: state.applicant.editingCourseId,
});

export default connect(mapStateToProps)(withStyles(styles)(LogicTab));
