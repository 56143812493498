import React, { useState, useEffect } from 'react';
import {
    Grid, RadioGroup, FormControlLabel, Radio, Typography, Divider,
} from '@mui/material';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import CodeBlock from '../../../../General/CodeBlock';
import { getLogicQuestionUrl } from '../../../../../helpers/PhotoHelper';

const LogicQuestionRow = ({
    logicInfo, user, handleChange, submitButtonPressed, classes,
}) => {
    const [questionError, setQuestionError] = useState('');

    useEffect(() => {
        if (submitButtonPressed && !logicInfo.answer) {
            setQuestionError('Please answer question');
        } else {
            setQuestionError('');
        }
    }, [submitButtonPressed, logicInfo]);
    return (
        <Grid item xs={12} className={classes.questionContainer}>
            <ReactMarkdown
                align="left"
                remarkPlugins={[remarkGfm]}
                components={{ code: CodeBlock }}
                rehypePlugins={[rehypeRaw]}
                linkTarget="_blank"
            >
                {logicInfo.question}
            </ReactMarkdown>
            {logicInfo.question_image_file_name
            && (
                <img
                    src={getLogicQuestionUrl(
                        user.s3_bucket,
                        logicInfo.id,
                        logicInfo.question_image_file_name,
                    )}
                    alt={logicInfo.question}
                    className={classes.responsiveImage}
                />
            )}
            <RadioGroup
                aria-label="answer"
                name="answer"
                row
                value={logicInfo.answer
                    ? logicInfo.answer
                    : ''}
                onChange={handleChange(logicInfo.id)}
            >
                <FormControlLabel value="A" control={<Radio color="primary" />} label="A" />
                <FormControlLabel value="B" control={<Radio color="primary" />} label="B" />
                <FormControlLabel value="C" control={<Radio color="primary" />} label="C" />
                <FormControlLabel value="D" control={<Radio color="primary" />} label="D" />
                <FormControlLabel value="I don't know." control={<Radio color="primary" />} label="I don't know" />
            </RadioGroup>
            <Typography className={classes.errorText}>
                {questionError}
            </Typography>
            <Divider />
        </Grid>
    );
};

LogicQuestionRow.propTypes = {
    logicInfo: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    handleChange: PropTypes.func.isRequired,
    submitButtonPressed: PropTypes.bool.isRequired,
};

export default LogicQuestionRow;
