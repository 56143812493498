import moment from 'moment-timezone';

export function formatDate(dateString) {
    let result = '';
    if (dateString && dateString.length > 0) {
        const d = moment.utc(dateString);
        result = d.format('YYYY-MM-DD');
    }
    return result;
}

export function shortDate(dateString) {
    let result = '';
    if (dateString && dateString.length > 0) {
        const d = moment.utc(dateString);
        result = d.format('MM-DD-YY');
    }
    return result;
}

export function extendedDate(dateString) {
    let result = '';
    if (dateString && dateString.length > 0) {
        const d = moment.utc(dateString);
        result = d.format('MMMM Do YYYY');
    }
    return result;
}

export function shortDateSlash(dateString) {
    let result = '';
    if (dateString && dateString !== '') {
        const d = moment.utc(dateString);
        result = d.format('MM/DD/YY');
    }
    return result;
}

export function shortDateTime(dateTimeString) {
    let result = '';
    if (dateTimeString && dateTimeString !== '') {
        const d = moment.utc(dateTimeString);
        result = d.tz('US/Central').format('MM/DD/YY [at] h:mm a');
    }
    return result;
}

export function shortDateTimeTimestamp(timestamp) {
    let result = '';
    if (timestamp) {
        const d = moment(timestamp);
        result = d.tz('US/Central').format('MMM D [at] h:mm a');
    }
    return result;
}

export function extendedDateTime(dateTimeString) {
    let result = '';
    if (dateTimeString && dateTimeString.length > 0) {
        const d = moment.utc(dateTimeString);
        result = d.tz('US/Central').format('MMMM Do, YYYY [at] h:mm a');
    }
    return result;
}

export function relativeDateTime(dateTimeString) {
    const today = new Date();
    let result = '';
    if (dateTimeString && dateTimeString.length > 0) {
        const d = moment.utc(dateTimeString);
        result = moment(d, 'YYYY').from(today);
    }
    return result;
}

export function monthYearNameDateTime(dateTimeString) {
    let result = '';
    if (dateTimeString && dateTimeString.length > 0) {
        result = moment(dateTimeString, 'YYYY-MM-DD').format('MMMM YYYY');
    }
    return result;
}
export function monthNameDayDateTime(dateTimeString) {
    let result = '';
    if (dateTimeString && dateTimeString.length > 0) {
        result = moment(dateTimeString, 'YYYY-MM-DD').format('dddd, MMMM Do');
    }
    return result;
}

export const findLastDayOfPreWork = (classroomBeginsDate) => {
    let result = '';
    if (classroomBeginsDate && classroomBeginsDate.length > 0) {
        result = moment(classroomBeginsDate).subtract(3, 'days').format('dddd, MMMM Do');
    }
    return result;
};

export function safariFormat(dateTimeString) {
    let result = '';
    if (dateTimeString && dateTimeString !== '') {
        const d = moment.utc(dateTimeString);
        result = d.format('MM/DD/YY HH:mm Z');
    }
    return result;
}

export function getTimestamp(dateTimeString) {
    let result = 0;
    if (dateTimeString && dateTimeString !== '') {
        const d = moment.utc(dateTimeString);
        result = d.unix();
    }
    return result;
}

export default {
    formatDate,
    shortDateTime,
    extendedDateTime,
    relativeDateTime,
    shortDateTimeTimestamp,
    monthYearNameDateTime,
    monthNameDayDateTime,
    findLastDayOfPreWork,
    safariFormat,
    getTimestamp,
};
