import React, { useState } from 'react';
import moment from 'moment';
import {
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Tooltip,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { shortStatusText, longStatusText } from '../../../helpers/CohortTextHelper';

const styles = {
    card: {
        margin: '15px',
    },
    cardContent: {
        marginTop: '10px',
    },
    cardHeading: {
        display: 'flex',
        marginBottom: '5px',
    },
    cardHeadingText: {
        width: '100%',
    },
};

const CohortListRow = ({ cohort, classes }) => {
    const [hoverText, setHoverText] = useState('Copy emails to clipboard.');
    const copyEmailListToClipboard = async () => {
        if (!navigator.clipboard) {
            setHoverText('Unable to copy.');
            return;
        }
        if (cohort && cohort.students && cohort.students.length > 0) {
            try {
                const emailList = cohort.students.map(student => student.email).join();
                await navigator.clipboard.writeText(emailList);
                setHoverText('Copied!');
            } catch (e) {
                setHoverText('Unable to copy.');
            }
        }
    };
    return (
        <Card className={classes.card} elevation={1}>
            <CardContent>
                <div className={classes.cardHeading}>
                    <h5 className={classes.cardHeadingText}>Cohort</h5>
                    {shortStatusText(cohort)}
                </div>
                <Divider />
                <Grid className={classes.cardContent} container>
                    <Grid item xs={6} sm={4}>
                        <Typography variant="h6">
                            Status
                        </Typography>
                        <Typography variant="body2">
                            {longStatusText(cohort)}
                        </Typography>
                        <Typography variant="h6">
                            Campus
                        </Typography>
                        {
                            cohort.campus
                            && <Typography variant="body2">{`${cohort.campus.code} - ${cohort.campus.name}`}</Typography>
                        }
                        <Typography variant="h6">
                            Program
                        </Typography>
                        {
                            cohort.course
                            && <Typography variant="body2">{`${cohort.course.name}`}</Typography>
                        }
                        <Typography variant="h6">
                            Instructors
                        </Typography>
                        {
                            cohort.employees
                            && cohort.employees.map(employee => (
                                <Typography variant="body2" key={`employee-${employee.id}`}>
                                    {`${employee.first_name} ${employee.last_name}`}
                                </Typography>
                            ))
                        }
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Typography variant="h6">
                            Applications open
                        </Typography>
                        <Typography variant="body2">
                            {cohort.applications_open}
                        </Typography>
                        <Typography variant="h6">
                            Applications close
                        </Typography>
                        <Typography variant="body2">
                            {cohort.applications_close}
                        </Typography>
                        <Typography variant="h6">
                            Tier 1 start
                        </Typography>
                        <Typography variant="body2">
                            {cohort.prework_start}
                        </Typography>
                        <Typography variant="h6">
                            Tier 2 start
                        </Typography>
                        <Typography variant="body2">
                            {cohort.classroom_start}
                        </Typography>
                        {
                            cohort.tier3_start
                            && (
                                <>
                                    <Typography variant="h6">
                                        Tier 3 start
                                    </Typography>
                                    <Typography variant="body2">
                                        {cohort.tier3_start}
                                    </Typography>
                                </>
                            )
                        }
                        <Typography variant="h6">
                            Graduation
                        </Typography>
                        <Typography variant="body2">
                            {cohort.graduation}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Typography variant="h6">
                            Target size
                        </Typography>
                        <Typography variant="body2">
                            {cohort.target_size}
                        </Typography>
                        <Typography variant="h6">
                            Confirmed
                        </Typography>
                        <Typography variant="body2">
                            {cohort.students && cohort.students.length}
                        </Typography>
                        <Typography variant="h6">
                            Job Placements
                        </Typography>
                        <Typography variant="body2">
                            {cohort.placement_count}
                        </Typography>
                        {
                            // Tracking cohort movement was added on 2022-01-05
                            moment(cohort.classroom_start).isAfter('2022-01-01')
                            && (
                                <>
                                    <Typography variant="h6">
                                        Transferred out
                                    </Typography>
                                    <Typography variant="body2">
                                        {cohort.previousStudents && cohort.previousStudents.filter(item => item.event_type === 'transfer_out').length}
                                    </Typography>
                                    <Typography variant="h6">
                                        Dropped out
                                    </Typography>
                                    <Typography variant="body2">
                                        {cohort.previousStudents && cohort.previousStudents.filter(item => item.event_type === 'dropped_out').length}
                                    </Typography>
                                </>
                            )
                        }

                        <br />
                        {
                            (
                                cohort.students
                                && cohort.students.length > 0
                            ) && (
                                <Tooltip title={hoverText}>
                                    <Button variant="outlined" type="button" onClick={copyEmailListToClipboard}>Copy Email List</Button>
                                </Tooltip>
                            )
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

CohortListRow.propTypes = {
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default withStyles(styles)(CohortListRow);
