import React from 'react';
import {
    Typography, FormLabel, FormControlLabel, Radio,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
    monthYearNameDateTime,
    monthNameDayDateTime,
    findLastDayOfPreWork,
} from '../../../../../helpers/DateFormatHelper';

const getCohortType = (type) => {
    let result = '';
    switch (type) {
    case 'full_time':
        result = 'Full-time';
        break;
    case 'part_time':
        result = 'Part-time';
        break;
    default:
        result = '';
    }
    return result;
};

const getSchedule = (cohort, classes) => {
    let result = (<></>);
    if (cohort.code === 'ux' || cohort.code === 'ux-academy') {
        result = (
            <Typography className={classes.cohort}>
                {`Full-time: ${monthNameDayDateTime(cohort.prework_start)} - ${monthNameDayDateTime(cohort.graduation)}`}
            </Typography>
        );
    } else if (cohort.cohort_type === 'full_time') {
        result = (
            <>
                <Typography className={classes.cohort}>
                    {`Part-time: ${monthNameDayDateTime(cohort.prework_start)} - ${findLastDayOfPreWork(cohort.classroom_start)} `}
                </Typography>
                <Typography className={classes.cohort}>
                    {`Full-time: ${monthNameDayDateTime(cohort.classroom_start)} - ${monthNameDayDateTime(cohort.graduation)}`}
                </Typography>
            </>
        );
    } else {
        result = (
            <Typography className={classes.cohort}>
                {`Part-time: ${monthNameDayDateTime(cohort.prework_start)} - ${monthNameDayDateTime(cohort.graduation)}`}
            </Typography>
        );
    }
    return result;
};

const ProfileInfoRow = ({
    cohort, classes, handleCohortChange,
}) => (
    <>
        <FormControlLabel
            control={(
                <Radio
                    checked={cohort.selected}
                    value
                    onClick={handleCohortChange(cohort.id)}
                    color="primary"
                />
            )}
        />
        <FormLabel style={{ cursor: 'pointer' }} onClick={handleCohortChange(cohort.id)} component="label" className={classes.campus}>
            {`${cohort.campus_name} ${getCohortType(cohort.cohort_type)} (${cohort.number_of_weeks} weeks) - `}
            {monthYearNameDateTime(cohort.graduation)}
            {' '}
            Graduation
        </FormLabel>
        <Typography className={classes.cohort}>
            {`Application Deadline: ${monthNameDayDateTime(cohort.applications_close)} `}
        </Typography>
        {
            cohort && getSchedule(cohort, classes)
        }
        <br />
    </>
);

ProfileInfoRow.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    handleCohortChange: PropTypes.func.isRequired,
};

export default ProfileInfoRow;
