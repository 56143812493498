import { combineReducers } from 'redux';

const defaultApplicant = {
    email: '',
    password: '',
    retypePassword: '',
    first_name: '',
    last_name: '',
    phone: '',
};

const editingCourseId = (state = 1, action) => {
    switch (action.type) {
    case 'SET_APPLICANT_COURSE_ID':
        return action.payload.course_id;
    default:
        return state;
    }
};

const editingApplicant = (state = defaultApplicant, action) => {
    switch (action.type) {
    case 'SET_EDITING_APPLICANT':
        return action.payload;
    case 'UNSET_EDITING_APPLICANT':
    case 'UNSET_USER':
        return defaultApplicant;
    default:
        return state;
    }
};

const activeApplications = (state = [], action) => {
    switch (action.type) {
    case 'SET_ACTIVE_APPLICATIONS':
        return action.payload;
    case 'UNSET_ACTIVE_APPLICATIONS':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const activeCourses = (state = [], action) => {
    switch (action.type) {
    case 'SET_ACTIVE_APPLICATION_COURSES':
        return action.payload;
    case 'UNSET_ACTIVE_APPLICATION_COURSES':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const defaultGeneralInformation = {
    first_name: '',
    last_name: '',
    middle_name: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    phone: '',
    income: '',
    personal_income: '',
    linkedin_account: '',
    twitter_account: '',
    website_link: '',
    birthdate: '',
    dependents: '',
    gender: '',
    gender_description: '',
    pronouns: '',
    level_of_interest: '',
    legal_status: '',
    education: '',
    employment_status: '',
    goal: '',
    geography: '',
    veteran: false,
    referral_source: '',
    race_hispanic: false,
    race_nativeamerican: false,
    race_asian: false,
    race_black: false,
    race_islander: false,
    race_white: false,
    race_other: false,
};

const editingGeneralInformation = (state = defaultGeneralInformation, action) => {
    switch (action.type) {
    case 'SET_EDITING_GENERAL_APPLICANT':
        return action.payload;
    case 'UNSET_EDITING_GENERAL_APPLICANT':
    case 'UNSET_USER':
        return defaultGeneralInformation;
    default:
        return state;
    }
};

const editingTechnicalInformation = (state = {}, action) => {
    let payloadCopy;
    switch (action.type) {
    case 'SET_EDITING_TECHNICAL_APPLICANT':
        payloadCopy = Object.assign({}, action.payload);
        if (!payloadCopy.resume_link) {
            payloadCopy.resume_link = '';
        }
        if (!payloadCopy.resume_explanation) {
            payloadCopy.resume_explanation = '';
        }
        return payloadCopy;
    case 'UNSET_EDITING_TECHNICAL_APPLICANT':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const editingProfileInformation = (state = [], action) => {
    switch (action.type) {
    case 'SET_EDITING_PROFILE_APPLICANT':
        return action.payload;
    case 'UNSET_EDITING_PROFILE_APPLICANT':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const editingLogicInformation = (state = [], action) => {
    switch (action.type) {
    case 'SET_EDITING_LOGIC_APPLICANT':
        return action.payload;
    case 'UNSET_EDITING_LOGIC_APPLICANT':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const startTabDetails = (state = {}, action) => {
    switch (action.type) {
    case 'SET_START_TAB_DETAILS':
        return action.payload;
    case 'UNSET_START_TAB_DETAILS':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const activeCohorts = (state = [], action) => {
    switch (action.type) {
    case 'SET_APPLICANT_ACTIVE_COHORTS':
        return action.payload;
    case 'UNSET_APPLICANT_ACTIVE_COHORTS':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const legalStatusOptions = [
    { value: 'US Citizen', label: 'US Citizen' },
    { value: 'Permanent Resident', label: 'Permanent Resident' },
    { value: 'Other', label: 'Other' },
];

const legalStatuses = (state = legalStatusOptions, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const highestEducationOptions = [
    { value: 'GED', label: 'GED or Highschool equivalent' },
    { value: 'High School', label: 'High School' },
    { value: 'Some College', label: 'Some College' },
    { value: 'Associates Degree', label: 'Associates Degree' },
    { value: 'Bachelors Degree', label: 'Bachelors Degree' },
    { value: 'Graduate Degree', label: 'Graduate Degree' },
];

const educationOptions = (state = highestEducationOptions, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const employmentOptions = [
    { value: 'Employed Full-time', label: 'Employed Full-time' },
    { value: 'Employed Part-time', label: 'Employed Part-time' },
    { value: 'Self-Employed', label: 'Self-Employed' },
    { value: 'Unemployed', label: 'Unemployed' },
];

const employmentStatuses = (state = employmentOptions, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const graduationPlans = [
    { value: 'Search for a job with Prime\'s support', label: 'Search for a job with Prime\'s support' },
    { value: 'Start a new business', label: 'Start a new business' },
    { value: 'Return to a previous job', label: 'Return to a previous job' },
    { value: 'Return to school', label: 'Return to school' },
    { value: 'Other', label: 'Other' },
];

const graduationPlanOptions = (state = graduationPlans, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const levelOfInterests = [
    { value: 'curious', label: 'I am curious about Prime but still unsure if this is the right direction for me.' },
    { value: 'interested', label: 'I am pretty interested in Prime but have some things to figure out to be sure.' },
    { value: 'very_interested', label: 'I am very interested in Prime and if accepted, I am confident I want to attend' },
];

const levelOfInterestOptions = (state = levelOfInterests, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const referralSources = [
    { value: 'Media Coverage', label: 'Media Coverage' },
    { value: 'Online Ad', label: 'Online Ad' },
    { value: 'Personal Referral', label: 'Personal Referral' },
    { value: 'Prime Alumni', label: 'Prime Alumni' },
    { value: 'Radio Ad', label: 'Radio Ad' },
    { value: 'Recruiting Event', label: 'Recruiting Event' },
    { value: 'Search Engine', label: 'Search Engine' },
    { value: 'Social Media Link', label: 'Social Media Link' },
    { value: 'World Usability Day', label: 'World Usability Day' },
    { value: 'Other', label: 'Other' },
];

const referralSourceOptions = (state = referralSources, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const genders = [
    { value: 'Female', label: 'Female' },
    { value: 'Male', label: 'Male' },
    { value: 'Non-Binary', label: 'Non-Binary' },
    { value: 'Gender-Fluid', label: 'Gender Fluid' },
    { value: 'Self-Describe', label: 'Prefer to self describe' },
    { value: 'Other', label: 'Other / Prefer not to say' },
];

const genderOptions = (state = genders, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const pronouns = [
    { value: 'she/her', label: 'she/her' },
    { value: 'he/him', label: 'he/him' },
    { value: 'they/them', label: 'they/them' },
    { value: 'ze/hir', label: 'ze/hir' },
    { value: 'Prefer-not-to-say', label: 'Prefer not to say' },
];

const pronounOptions = (state = pronouns, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const geography = [
    { value: 'Urban', label: 'Urban' },
    { value: 'Suburban', label: 'Suburban' },
    { value: 'Rural', label: 'Rural' },
];

const geographyOptions = (state = geography, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const veteran = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];

const veteranOptions = (state = veteran, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const paymentTypes = [
    { value: '', label: '' },
    { value: 'Prepay', label: 'With out-of-pocket funds or independent financing.' },
    { value: 'Financing', label: 'With financing from one of the borrowers that partner directly with Prime.' },
    { value: 'Scholarship', label: 'I can only attend the program if supported by grants/scholarships.' },
];

const paymentOptions = (state = paymentTypes, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const applicationStep = (state = 'start', action) => {
    let result = 'start';
    switch (action.type) {
    case 'SET_APPLICANT_STEP':
        if (action.payload) {
            result = action.payload;
        }
        return result;
    default:
        return state;
    }
};

const defaultUnsavedChanges = {
    general: false,
    technical: false,
    profile: false,
    logic: false,
};

const unsavedChanges = (state = defaultUnsavedChanges, action) => {
    switch (action.type) {
    case 'SET_UNSAVED_GENERAL_CHANGE':
        return { ...state, general: true };
    case 'UNSET_UNSAVED_GENERAL_CHANGE':
        return { ...state, general: false };
    case 'SET_UNSAVED_TECHNICAL_CHANGE':
        return { ...state, technical: true };
    case 'UNSET_UNSAVED_TECHNICAL_CHANGE':
        return { ...state, technical: false };
    case 'SET_UNSAVED_PROFILE_CHANGE':
        return { ...state, profile: true };
    case 'UNSET_UNSAVED_PROFILE_CHANGE':
        return { ...state, profile: false };
    case 'SET_UNSAVED_LOGIC_CHANGE':
        return { ...state, logic: true };
    case 'UNSET_UNSAVED_LOGIC_CHANGE':
        return { ...state, logic: false };
    case 'UNSET_ALL_UNSAVED_CHANGES':
        return defaultUnsavedChanges;
    default:
        return state;
    }
};

export default combineReducers({
    editingApplicant,
    activeApplications,
    activeCourses,
    editingGeneralInformation,
    legalStatuses,
    educationOptions,
    employmentStatuses,
    graduationPlanOptions,
    referralSourceOptions,
    genderOptions,
    pronounOptions,
    geographyOptions,
    veteranOptions,
    levelOfInterestOptions,
    editingTechnicalInformation,
    startTabDetails,
    editingProfileInformation,
    editingLogicInformation,
    editingCourseId,
    activeCohorts,
    unsavedChanges,
    paymentOptions,
    applicationStep,
});
