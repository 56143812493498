import React from 'react';
import {
    Typography, TextField, Grid, FormLabel, FormControlLabel, Checkbox, Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from '../../../../General/Select';
import { races } from '../../../../../config/labels';

const styles = {
    background: {
        backgroundColor: '#E8E8E8',
        margin: '10px',
        padding: '20px',
    },
    header: {
        fontSize: '25px',
    },
    divider: {
        marginTop: '-10px',
    },
    whiteTextField: {
        backgroundColor: 'white',
    },
    labelHeading: {
        fontWeight: 'bold',
        padding: '10px',
        fontSize: '11pt',
    },
    datePicker: {
        backgroundColor: 'white',
        width: '100%',
        padding: '6px',
        borderRadius: '2px',
    },
    title: {
        fontSize: '30px',
    },
    body: {
        fontSize: '13px',
    },
    content: {
        padding: '30px',
    },
    bodyText: {
        fontSize: '13px',
    },
    reactSelect: {
        paddingTop: '2px',
        width: '100%',
        padding: '0px',
        '& .react-select__control': {
            height: '40px',
            borderRadius: '3px',
            width: '100%',
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
    },
    raceCheckbox: {
        display: 'block',
        paddingLeft: 10,
    },
};

const Demographics = ({
    classes,
    dispatch,
    genderOptions,
    geographyOptions,
    veteranOptions,
    editingApplicant,
    onApplicantChange = (property, value) => {},
}) => {
    // TODO: do any fields need be validated; fix birthday error
    const handleChangeFor = propertyName => (event) => {
        onApplicantChange(propertyName, event.target ? event.target.value : event);
    };

    const handleCheckboxChange = name => (event) => {
        const { checked } = event.target;

        onApplicantChange(name, checked);
    };
    return (
        <>
            <Grid container spacing={2} className={classes.background}>
                <Grid item xs={12}>
                    <Typography className={classes.header}> Demographics </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Data from the questions in this section help us to
                        seek scholarship opportunities for Prime students; we will use
                        your answers to identify scholarship opportunities for you.
                        These questions are optional and your answers will not affect
                        your admissions decision.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel className={classes.labelHeading} component="label">Birthdate</FormLabel>
                    <br />
                    <TextField
                        type="date"
                        value={editingApplicant.birthdate}
                        onChange={handleChangeFor('birthdate')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="dense"
                        className={classes.datePicker}
                    />
                    <br />
                    <br />
                    <FormLabel className={classes.labelHeading} component="label">Gender</FormLabel>
                    <Select
                        value={editingApplicant.gender}
                        onChange={(gender) => {
                            onApplicantChange('gender_description', '');
                            onApplicantChange('gender', gender);
                        }}
                        options={genderOptions}
                        classNamePrefix="react-select"
                        className={classes.reactSelect}
                    />
                    <br />
                    {editingApplicant.gender
                     && editingApplicant.gender === 'Self-Describe'
                     && (
                         <>
                             <FormLabel className={classes.labelHeading} component="label">Gender Description</FormLabel>
                             <TextField
                                 type="text"
                                 fullWidth
                                 className={classes.whiteTextField}
                                 variant="outlined"
                                 value={editingApplicant.gender_description}
                                 margin="dense"
                                 onChange={handleChangeFor('gender_description')}
                             />
                         </>
                     )}
                    <FormLabel className={classes.labelHeading} component="label">How many legal dependents do you have?</FormLabel>
                    <TextField
                        type="number"
                        fullWidth
                        className={classes.whiteTextField}
                        variant="outlined"
                        value={editingApplicant.dependents}
                        margin="dense"
                        onChange={handleChangeFor('dependents')}
                    />
                    <br />
                    <br />
                    <FormLabel className={classes.labelHeading} component="label">How would you classify your home&apos;s geography?</FormLabel>
                    <Select
                        value={editingApplicant.geography}
                        onChange={handleChangeFor('geography')}
                        options={geographyOptions}
                        classNamePrefix="react-select"
                        className={classes.reactSelect}
                    />
                    <br />
                    <FormLabel className={classes.labelHeading} component="label">Are you a United States Military Veteran?</FormLabel>
                    <Select
                        value={editingApplicant.veteran}
                        onChange={handleChangeFor('veteran')}
                        options={veteranOptions}
                        classNamePrefix="react-select"
                        className={classes.reactSelect}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel className={classes.labelHeading} component="label">With which racial or ethnic group(s) do you identify?</FormLabel>
                    {Object.entries(races).map(([raceKey, raceLabel]) => (
                        <FormControlLabel
                            key={raceKey}
                            className={classes.raceCheckbox}
                            control={(
                                <Checkbox
                                    checked={editingApplicant[raceKey]}
                                    value={raceKey}
                                    onChange={handleCheckboxChange(raceKey)}
                                    color="primary"
                                />
                            )}
                            label={raceLabel}
                        />
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

Demographics.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    genderOptions: PropTypes.instanceOf(Array).isRequired,
    geographyOptions: PropTypes.instanceOf(Array).isRequired,
    veteranOptions: PropTypes.instanceOf(Array).isRequired,
    dispatch: PropTypes.func.isRequired,
    editingApplicant: PropTypes.instanceOf(Object).isRequired,
    onApplicantChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    genderOptions: state.applicant.genderOptions,
    geographyOptions: state.applicant.geographyOptions,
    veteranOptions: state.applicant.veteranOptions,
});

export default connect(mapStateToProps)(withStyles(styles)(Demographics));
