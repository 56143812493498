import React, { useState, useEffect, useCallback } from 'react';
import {
    Typography, Paper, TextField, Grid, FormLabel, Button, Divider, Input,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Demographics from './Demographics';
import Cohort from './Cohort';
import Select from '../../../../General/Select';
import StateAutocomplete from '../../../../../helpers/StateAutocomplete';

const useStyles = makeStyles({
    spacer: {
        width: '100%',
        height: '28px',
    },
    content: {
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '64px',
        marginBottom: '250px',
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontSize: 14,
    },
    buttonContainer: {
        padding: '20px',
    },
    campus: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '10px',
        marginLeft: '-20px',
    },
    cohort: {
        fontSize: '14px',
        paddingLeft: '28px',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    reactSelect: {
        paddingTop: '2px',
        width: '100%',
        padding: '0px',
        '& .react-select__control': {
            height: '40px',
            borderRadius: '3px',
            width: '100%',
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
    },
    helperText: {
        margin: '8px 14px 0',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    formLabel: {
        padding: '10px',
        fontSize: '11pt',
    },
});

const BaseGeneralTab = ({
    applicant,
    courseId,
    activeCohorts,
    resumeUploadEnabled,
    onApplicantChange = (property, value) => {},
    onActiveCohortChange = (cohorts) => {},
    onErrorMessage = (title, body) => {},
    onSubmit = ({ applicant, courseId, selectedFile }) => {}, // eslint-disable-line no-shadow
    onFileSelect = (file) => {},
}) => {
    const classes = useStyles();

    const levelOfInterestOptions = useSelector(store => store.applicant.levelOfInterestOptions);
    const legalStatuses = useSelector(store => store.applicant.legalStatuses);
    const educationOptions = useSelector(store => store.applicant.educationOptions);
    const employmentStatuses = useSelector(store => store.applicant.employmentStatuses);
    const graduationPlanOptions = useSelector(store => store.applicant.graduationPlanOptions);
    const referralSourceOptions = useSelector(store => store.applicant.referralSourceOptions);
    const pronounOptions = useSelector(store => store.applicant.pronounOptions);

    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [zipCodeError, setZipCodeError] = useState('');
    const [stateError, setStateError] = useState('');
    const [cityError, setCityError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [legalStatusError, setLegalStatusError] = useState('');
    const [educationError, setEducationError] = useState('');
    const [employmentError, setEmploymentError] = useState('');
    const [goalError, setGoalError] = useState('');
    const [resumeError, setResumeError] = useState('');
    const [selectedFile, setSelectedFile] = useState({});
    const [timingError, setTimingError] = useState('');

    // After a form is submitted with validation errors,
    // we will continue to revalidate after every change
    const [validateOnChange, setValidateOnChange] = useState(false);
    // Tracks whether a validation is required
    const [validationFlag, setValidationFlag] = useState(false);
    // Used to debounce validations (prevent too many at once)
    const [validationTimer, setValidationTimer] = useState(undefined);

    const clearErrors = () => {
        setFirstNameError('');
        setLastNameError('');
        setAddressError('');
        setZipCodeError('');
        setStateError('');
        setCityError('');
        setPhoneError('');
        setLegalStatusError('');
        setEducationError('');
        setEmploymentError('');
        setGoalError('');
        setResumeError('');
        setTimingError('');
    };

    const validateForm = useCallback(() => {
        clearErrors();
        let valid = true;
        if (!applicant.first_name || applicant.first_name === '') {
            valid = false;
            setFirstNameError('First name is required.');
        }
        if (!applicant.last_name || applicant.last_name === '') {
            valid = false;
            setLastNameError('Last name is required.');
        }
        if (!applicant.address || applicant.address === '') {
            valid = false;
            setAddressError('Address is required.');
        }
        if (!applicant.city || applicant.city === '') {
            valid = false;
            setCityError('City is required.');
        }
        if (!applicant.state || applicant.state === '') {
            valid = false;
            setStateError('State is required.');
        }
        if (!applicant.zip_code || applicant.zip_code === '') {
            valid = false;
            setZipCodeError('Zip code is required.');
        }
        if (!applicant.phone || applicant.phone === '') {
            valid = false;
            setPhoneError('Phone is required.');
        }
        if (!applicant.legal_status) {
            valid = false;
            setLegalStatusError('Legal status is required.');
        }
        if (!applicant.education) {
            valid = false;
            setEducationError('Education level is required.');
        }
        if (!applicant.employment_status) {
            valid = false;
            setEmploymentError('Employment status is required.');
        }
        if (!applicant.goal) {
            valid = false;
            setGoalError('Goal is required.');
        }
        if (courseId === 2
            && resumeUploadEnabled
            && !selectedFile.type
            && !applicant.resume_updated_at) {
            valid = false;
            setResumeError('Resume is required.');
        }
        if (activeCohorts) {
            const selectedCohorts = activeCohorts.filter(cohort => cohort.selected);
            if (selectedCohorts.length === 0) {
                valid = false;
                setTimingError('Graduation timing is required.');
            }
        }

        return valid;
    }, [
        // any variable used by validateForm must be listed here:
        applicant,
        courseId,
        resumeUploadEnabled,
        selectedFile,
        activeCohorts,
    ]);

    useEffect(() => {
        if (validationFlag) {
            setValidationFlag(false);
            if (validationTimer) {
                clearTimeout(validationTimer);
            }
            setValidationTimer(
                setTimeout(() => { validateForm(); }, 250),
            );
        }
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (validationTimer) {
                clearTimeout(validationTimer);
            }
        };
    }, [validationFlag, validationTimer, validateForm]);

    const handleCohortChange = cohortId => (event) => {
        // Look through the activeCohorts array,
        // and mark the modified cohort as selected / deselected
        const updatedCohorts = activeCohorts.map((cohort) => {
            if (cohort.id === cohortId) {
                return {
                    ...cohort,
                    selected: true,
                };
            }
            return { ...cohort, selected: false };
        });
        if (validateOnChange) {
            setValidationFlag(true);
        }
        onActiveCohortChange(updatedCohorts);
    };

    const handleChangeFor = propertyName => (event) => {
        if (validateOnChange) {
            setValidationFlag(true);
        }

        // Grab values from both <Select /> components, and regular inputs
        const value = event.target ? event.target.value : event;

        onApplicantChange(propertyName, value);
    };

    const onFileChange = async (event) => {
        const fileToUpload = event.target.files[0];
        const acceptedImageTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (fileToUpload.size > 1048576 * 10) {
            onErrorMessage('File is Too Big', 'File size limit is 10MB.');
        } else if (acceptedImageTypes.includes(fileToUpload.type)) {
            if (validateOnChange) {
                setValidationFlag(true);
            }
            setSelectedFile(fileToUpload);

            onFileSelect(fileToUpload);
        } else {
            onErrorMessage('Invalid File Type', 'Please select a pdf, doc or docx file type.');
        }
    };

    const submitGeneralInformation = (event) => {
        event.preventDefault();
        const valid = validateForm();
        if (!valid) {
            setValidateOnChange(true);
            onErrorMessage('Missing Fields', 'Please complete all required fields before continuing.');
            return;
        }
        setValidateOnChange(false);
        clearErrors();

        onSubmit({
            applicant,
            activeCohorts,
            courseId,
            selectedFile,
        });
    };
    const resumeFileName = selectedFile.name || applicant.resume_file_name || '';
    return (
        <>
            <Paper className={classes.content}>
                <form onSubmit={submitGeneralInformation}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h2">General</Typography>
                            <Typography>
                                Please share some information
                                about yourself in the form below.
                                Fields marked with * are required.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Contact
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="First name"
                                variant="outlined"
                                fullWidth
                                value={applicant.first_name}
                                margin="dense"
                                onChange={handleChangeFor('first_name')}
                            />
                            {
                                firstNameError && firstNameError !== '' && (
                                    <Typography className={classes.errorText}>
                                        {firstNameError}
                                    </Typography>
                                )
                            }
                            <br />
                            <br />
                            <TextField
                                label="Middle name"
                                variant="outlined"
                                fullWidth
                                value={applicant.middle_name}
                                margin="dense"
                                onChange={handleChangeFor('middle_name')}
                            />
                            <br />
                            <br />
                            <TextField
                                required
                                label="Last name"
                                variant="outlined"
                                fullWidth
                                value={applicant.last_name}
                                margin="dense"
                                onChange={handleChangeFor('last_name')}
                            />
                            {
                                lastNameError && lastNameError !== '' && (
                                    <Typography className={classes.errorText}>
                                        {lastNameError}
                                    </Typography>
                                )
                            }
                            <br />
                            <br />
                            <TextField
                                required
                                label="Phone"
                                variant="outlined"
                                helperText="Format: 612-222-8108"
                                fullWidth
                                value={applicant.phone}
                                margin="dense"
                                onChange={handleChangeFor('phone')}
                            />
                            {
                                phoneError && phoneError !== '' && (
                                    <Typography className={classes.errorText}>
                                        {phoneError}
                                    </Typography>
                                )
                            }
                            <br />
                            <br />
                            <FormLabel className={classes.formLabel} component="label">Pronouns</FormLabel>
                            <br />
                            <Select
                                value={applicant.pronouns}
                                onChange={handleChangeFor('pronouns')}
                                options={pronounOptions}
                                className={classes.reactSelect}
                            />
                        </Grid>
                        <div className={classes.spacer} />
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Address
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Address"
                                variant="outlined"
                                fullWidth
                                value={applicant.address}
                                margin="dense"
                                onChange={handleChangeFor('address')}
                            />
                            {
                                addressError && addressError !== '' && (
                                    <Typography className={classes.errorText}>
                                        {addressError}
                                    </Typography>
                                )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label="City"
                                variant="outlined"
                                value={applicant.city}
                                margin="dense"
                                onChange={handleChangeFor('city')}
                                style={{ width: '400px', display: 'inline-block', marginRight: '50px' }}
                            />
                            {
                                cityError && cityError !== '' && (
                                    <Typography className={classes.errorText}>
                                        {cityError}
                                    </Typography>
                                )
                            }
                            <StateAutocomplete
                                value={applicant.state}
                                onChange={state => onApplicantChange('state', state)}
                            />
                            {
                                stateError && stateError !== '' && (
                                    <Typography className={classes.errorText}>
                                        {stateError}
                                    </Typography>
                                )
                            }
                            <br />
                            <br />
                            <TextField
                                required
                                label="Zip code"
                                variant="outlined"
                                style={{ width: '300px', display: 'inline-block' }}
                                value={applicant.zip_code}
                                margin="dense"
                                onChange={handleChangeFor('zip_code')}
                            />
                            {
                                zipCodeError && zipCodeError !== '' && (
                                    <Typography className={classes.errorText}>
                                        {zipCodeError}
                                    </Typography>
                                )
                            }
                            <div style={{ margin: '38px' }} />
                            <FormLabel className={classes.formLabel} component="label">What best describes where you&apos;re at with considering Prime?</FormLabel>
                            <Select
                                value={applicant.level_of_interest}
                                onChange={handleChangeFor('level_of_interest')}
                                options={levelOfInterestOptions}
                                className={classes.reactSelect}
                            />
                            <Typography className={classes.helperText}>
                                This isn’t a part of your final application submission, it
                                just helps us gauge how we can best support you in the
                                process.
                            </Typography>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Education, Employment and Goals
                            </Typography>
                            <Divider />

                            {
                                courseId === 2 && resumeUploadEnabled && (
                                    <>
                                        <Typography> Resume </Typography>
                                        {
                                            resumeFileName
                                        }
                                        <Input
                                            id="resumeUpload"
                                            type="file"
                                            inputProps={{
                                                accept: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                            }}
                                            disableUnderline
                                            onChange={onFileChange}
                                        />
                                        <br />
                                        <Typography className={classes.bodyText}>
                                            Please upload your resume as a Word document or PDF.
                                        </Typography>
                                        {
                                            resumeError && resumeError !== '' && (
                                                <Typography className={classes.errorText}>
                                                    {resumeError}
                                                </Typography>
                                            )
                                        }
                                        <br />
                                    </>
                                )
                            }
                            <FormLabel className={classes.formLabel} component="label">Legal Status *</FormLabel>
                            <Select
                                value={applicant.legal_status}
                                onChange={handleChangeFor('legal_status')}
                                options={legalStatuses}
                                className={classes.reactSelect}
                            />
                            {
                                legalStatusError && legalStatusError !== '' && (
                                    <Typography className={classes.errorText}>
                                        {legalStatusError}
                                    </Typography>
                                )
                            }
                            <br />
                            <FormLabel className={classes.formLabel} component="label">Highest level of education completed *</FormLabel>
                            <Select
                                onChange={handleChangeFor('education')}
                                options={educationOptions}
                                value={applicant.education}
                                className={classes.reactSelect}
                            />
                            {
                                educationError && educationError !== '' && (
                                    <Typography className={classes.errorText}>
                                        {educationError}
                                    </Typography>
                                )
                            }
                            <br />
                            <FormLabel className={classes.formLabel} component="label">Employment status *</FormLabel>
                            <Select
                                value={applicant.employment_status}
                                onChange={handleChangeFor('employment_status')}
                                options={employmentStatuses}
                                className={classes.reactSelect}
                            />
                            {
                                employmentError && employmentError !== '' && (
                                    <Typography className={classes.errorText}>
                                        {employmentError}
                                    </Typography>
                                )
                            }
                            <br />
                            <FormLabel className={classes.formLabel} component="label">After graduating from Prime my goal is to... *</FormLabel>
                            <Select
                                value={applicant.goal}
                                onChange={handleChangeFor('goal')}
                                options={graduationPlanOptions}
                                className={classes.reactSelect}
                            />
                            {
                                goalError && goalError !== '' && (
                                    <Typography className={classes.errorText}>
                                        {goalError}
                                    </Typography>
                                )
                            }
                        </Grid>
                        <div className={classes.spacer} />
                        <Grid item xs={12}>
                            <Typography variant="h5">Income</Typography>
                            <Divider />
                            <TextField
                                style={{ marginTop: 20 }}
                                label="Current Annual Household Income"
                                type="number"
                                variant="outlined"
                                helperText="Example: 50000
                                (This will help us determine your eligibility for aid and will be verified later.)"
                                fullWidth
                                value={applicant.income}
                                margin="dense"
                                onChange={handleChangeFor('income')}
                            />
                            <br />
                            <TextField
                                style={{ marginTop: 27 }}
                                label="Annual Personal Income"
                                type="number"
                                variant="outlined"
                                helperText="Example: 30000
                                (We use this number for our outcomes reporting.)"
                                fullWidth
                                value={applicant.personal_income}
                                margin="dense"
                                onChange={handleChangeFor('personal_income')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <br />
                            <Typography variant="h5">
                                Links and Social Accounts
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Link to your LinkedIn profile"
                                variant="outlined"
                                type="url"
                                helperText="Example: http://www.linkedin.com/in/myprofile"
                                fullWidth
                                value={applicant.linkedin_account}
                                margin="dense"
                                onChange={handleChangeFor('linkedin_account')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Your personal website if you have one"
                                variant="outlined"
                                type="url"
                                helperText="Example: http://myawesomewebsite.com"
                                fullWidth
                                value={applicant.website_link}
                                margin="dense"
                                onChange={handleChangeFor('website_link')}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                label="Your Twitter username"
                                variant="outlined"
                                helperText="Example: goprimeacademy"
                                fullWidth
                                value={applicant.twitter_account}
                                margin="dense"
                                onChange={handleChangeFor('twitter_account')}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <br />
                            <br />
                        </Grid>
                        <Demographics
                            editingApplicant={applicant}
                            onApplicantChange={onApplicantChange}
                        />
                        <Grid item xs={12}>
                            <br />
                            <FormLabel className={classes.formLabel} component="label">How did you hear about Prime?</FormLabel>
                            <Select
                                value={applicant.referral_source}
                                onChange={handleChangeFor('referral_source')}
                                options={referralSourceOptions}
                                className={classes.reactSelect}
                            />
                            <br />
                        </Grid>
                        {activeCohorts
                            && (
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Graduation Timing Preference
                                    </Typography>
                                    <Divider />
                                    <Typography className={classes.bodyText}>
                                        Select the graduation date that is most ideal
                                        for you and your career goals. *
                                    </Typography>
                                    {
                                        activeCohorts.map(cohort => (
                                            <Cohort
                                                key={cohort.id}
                                                cohort={cohort}
                                                classes={classes}
                                                handleCohortChange={handleCohortChange}
                                            />
                                        ))
                                    }
                                    <br />
                                    {
                                        timingError && timingError !== '' && (
                                            <Typography className={classes.errorText}>
                                                {timingError}
                                            </Typography>
                                        )
                                    }
                                </Grid>
                            )
                        }
                    </Grid>
                    <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            Save Your Application and Continue
                        </Button>
                    </Grid>
                </form>
            </Paper>
        </>
    );
};

BaseGeneralTab.propTypes = {
    applicant: PropTypes.instanceOf(Object).isRequired,
    courseId: PropTypes.number,
    activeCohorts: PropTypes.instanceOf(Array),
    resumeUploadEnabled: PropTypes.bool,
    onApplicantChange: PropTypes.func.isRequired,
    onActiveCohortChange: PropTypes.func,
    onErrorMessage: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onFileSelect: PropTypes.func,
};

BaseGeneralTab.defaultProps = {
    courseId: null,
    activeCohorts: null,
    resumeUploadEnabled: true,
    onActiveCohortChange: () => {},
    onFileSelect: () => {},
};

export default BaseGeneralTab;
